import { ActionTypes } from '@/store/actionTypes'
import {
  ContactCancel,
  ContactCancelActionTypes,
} from '@/store/contactCancel/types'

export const setContactCancel = (
  payload: ContactCancel
): ContactCancelActionTypes => {
  return {
    type: ActionTypes.contactCancel,
    payload,
  }
}

export const clearState = () => {
  return {
    type: ActionTypes.clearState,
  }
}
