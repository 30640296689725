import axios from 'axios'
import styled from 'styled-components'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { navigate } from 'gatsby'

import useFormInput from '@/hooks/useFormInput'
import { theme } from '@/styles/theme'
import { RootState } from '@/store/index'
import { clearState } from '@/store/contactCancel/action'
import {
  ConfirmFormAction,
  FileInfo,
  FormContent,
  FormMain,
} from '@/styles/form'

import { Seo } from '@/components/Seo'
import { Layout } from '@/components/layouts/Layout'
import { Title } from '@/components/elements/Title'
import { BreadCrumbs } from '@/components/layouts/Breadcrumbs'
import { Button } from '@/components/elements/Button'
import { FormLabel } from '@/components/layouts/Form/FormLabel'

export default function cancelConfirm() {
  const [isLoading, setIsLoading] = useState(false)

  const form = useSelector((state: RootState) => state.contactCancel)
  const dispatch = useDispatch()
  const { dateParseString } = useFormInput()

  const handlePopstate = () => {
    history.pushState(null, '', null)
    dispatch(clearState())
  }

  useLayoutEffect(() => {
    const isReload = sessionStorage.getItem('isReload')
    if (isReload && isReload !== '') {
      navigate('/contact/cancel/')
    }
  }, [])

  const onUnload = () => {
    sessionStorage.setItem('isReload', '1')
  }

  useEffect(() => {
    window.addEventListener('popstate', handlePopstate, false)

    return () => {
      window.removeEventListener('popstate', handlePopstate, false)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('beforeunload', onUnload, false)

    return () => {
      sessionStorage.setItem('isReload', '')
      window.removeEventListener('beforeunload', onUnload, false)
    }
  })

  const modifyForm = () => {
    navigate('/contact/cancel/')
  }

  const sendForm = () => {
    setIsLoading(true)

    let fileArr: File[] = []
    if (form.other_files) {
      const otherFileArr: File[] = form.other_files
      fileArr = form.require_files.concat(otherFileArr)
    } else {
      fileArr = form.require_files
    }

    let submitData = new FormData()
    submitData.append('phone_number', form.tel)
    submitData.append('mail_address', form.email)
    submitData.append('name', form.name)
    submitData.append('address', form.address)
    submitData.append('zip_code', form.zip_code)
    submitData.append('birthday', dateParseString(form.birthday, false))
    fileArr.map((file) => submitData.append('files[]', file))

    axios
      .post('/cancellation', submitData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(clearState())
        setIsLoading(false)
        if (response.status == 200) {
          navigate('/contact/cancel/done/')
        } else {
          navigate('/contact/error/')
        }
      })
      .catch(() => {
        setIsLoading(false)
        navigate('/contact/error/')
      })
  }

  const breadCrumbs = [
    { name: 'トップ', path: '/' },
    { name: 'お問い合わせ', path: '/contact/' },
    { name: '解約申請フォーム' },
  ]

  return (
    <Layout isLoading={isLoading} isForm>
      <Seo
        title="お問い合わせ内容確認 - お問い合わせ"
        canonical="https://su-pay.jp/contact/cancel/confirm/"
        noIndex
      />
      <Title as="h1" bg={true}>
        お問い合わせ
      </Title>
      <MainWrapper>
        <BreadCrumbs breadCrumbs={breadCrumbs} />
        <TitleWrapper>
          <Title as="h2" align="left" color={theme.colors.supayBlue}>
            入力内容のご確認
          </Title>
        </TitleWrapper>
        <FormMain>
          <FormLabel isRequired>お名前</FormLabel>
          <FormContent>
            <p>{form.name}</p>
          </FormContent>
          <FormLabel isRequired>生年月日</FormLabel>
          <FormContent>
            <p>{dateParseString(form.birthday, false)}</p>
          </FormContent>
          <FormLabel isRequired>登録していた電話番号</FormLabel>
          <FormContent>
            <p>{form.tel}</p>
          </FormContent>
          <FormLabel isRequired>今回お手続きの連絡用メールアドレス</FormLabel>
          <FormContent>
            <p>{form.email}</p>
          </FormContent>
          <FormLabel isRequired>郵便番号</FormLabel>
          <FormContent>
            <p>{form.zip_code}</p>
          </FormContent>
          <FormLabel isRequired>住所</FormLabel>
          <FormContent>
            <p>{form.address}</p>
          </FormContent>
          <FormLabel isRequired>本人確認書類</FormLabel>
          <FormContent>
            {form.require_files.map((file, i) => (
              <FileInfo key={`${file.name}${i}`}>
                <p>{file.name}</p>
              </FileInfo>
            ))}
          </FormContent>
          {form.other_files.length > 0 && (
            <>
              <FormLabel>その他の書類</FormLabel>
              <FormContent>
                {form.other_files.map((file, i) => (
                  <FileInfo key={`${file.name}${i}`}>
                    <p>{file.name}</p>
                  </FileInfo>
                ))}
              </FormContent>
            </>
          )}
        </FormMain>
        <ConfirmFormAction>
          <Button showArrowLeft bgColor="#D0D5DB" onClick={() => modifyForm()}>
            戻る
          </Button>
          <Button showArrowRight onClick={() => sendForm()}>
            送信する
          </Button>
        </ConfirmFormAction>
      </MainWrapper>
    </Layout>
  )
}

const MainWrapper = styled.div`
  width: ${(props) => props.theme.containerWidth};

  margin: 22px auto 0;

  @media screen and (max-width: 1047px) {
    width: 100%;

    padding: 0 ${(props) => props.theme.containerMargin};
    margin: 12px auto 0;
  }
`

const TitleWrapper = styled.div`
  margin: 102px 0 70px;

  h2 {
    line-height: 52px;
    text-align: left;
  }

  @media screen and (max-width: 1047px) {
    margin: 60px 0 22px;

    h2 {
      font-size: 20px;
      line-height: 24px;
    }
  }
`
